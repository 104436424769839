import React, { useEffect } from "react"
import Meta from "../utilities/seo"
import { useStaticQuery, graphql } from "gatsby"

const Ele = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          name: {
            eq: "XENO_Investment [UG]_Investment Management Agreement_Approved_2022_09_19"
          }
        }
      ) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `)

  useEffect(() => {
    data.allFile.edges.forEach(file => {
      if (
        file.node.name ==
        "XENO_Investment [UG]_Investment Management Agreement_Approved_2022_09_19"
      ) {
        let url = "https://myxeno.com" + file.node.publicURL
        window.location.href = url
      }
    })
  }, [])
  return (
    <>
      <Meta title="XENO Client Agreement" />
    </>
  )
}

export default Ele
